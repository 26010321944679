export default function Header() {
    return (
        <div className="px-2 py-3 border-b-2 border-l-green-600 bg-green-100">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <div className="cursor-pointer">
                        <svg
                            fill="#ffffff" // White for better contrast
                            height="30px"
                            width="30px"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <g>
                                <path d="M256,0C114.616,0,0,114.614,0,256s114.616,256,256,256c141.386,0,256-114.614,256-256S397.386,0,256,0z M428.717,135.014 c17.777,25.264,29.667,53.969,34.961,84.403l-76.655-17.69L428.717,135.014z M376.984,83.281l-66.712,41.695l-17.69-76.654 C323.016,53.617,351.72,65.504,376.984,83.281z M219.418,48.322l-17.69,76.654l-66.712-41.693 C160.28,65.506,188.984,53.617,219.418,48.322z M83.281,135.016l41.695,66.712l-76.654,17.69 C53.617,188.984,65.506,160.28,83.281,135.016z M83.281,376.983c-17.775-25.263-29.666-53.968-34.959-84.402l76.654,17.69 L83.281,376.983z M135.014,428.716l66.714-41.695l17.69,76.657C188.982,458.383,160.279,446.494,135.014,428.716z M256,421.29 l-24.496-106.151l-92.381,57.737l57.737-92.38L90.71,256l106.151-24.496l-57.74-92.381l92.383,57.737L256,90.71l24.496,106.15 l92.38-57.739l-57.739,92.383L421.289,256l-106.15,24.496l57.739,92.381l-92.381-57.737L256,421.29z M292.582,463.679 l17.69-76.655l66.712,41.695C351.72,446.496,323.016,458.385,292.582,463.679z M428.717,376.984l-41.695-66.712l76.655-17.69 C458.383,323.016,446.494,351.72,428.717,376.984z"></path>
                            </g>
                        </svg>
                    </div>
                    <p className="font-bold cursor-pointer">Horaquest</p>
                </div>

                <div className="flex gap-4">
                    <div className="flex items-center gap-1 justify-center">
                        <div>
                            <svg width="22px" height="22px" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z" fill="#ffffff"></path>
                            </svg>
                        </div>
                        <div>8595812355</div>
                    </div>
                    <div className="flex items-center gap-1 justify-center">
                        <div>
                            <svg
                                height="22px"
                                width="22px"
                                version="1.1"
                                id="_x32_"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                fill="#ffffff" // White for better contrast
                            >
                                <path d="M452.29,74.535H59.71C26.728,74.535,0,101.263,0,134.236v243.527c0,32.974,26.728,59.702,59.71,59.702H452.29 c32.973,0,59.71-26.728,59.71-59.702V134.236C512,101.263,485.264,74.535,452.29,74.535z M483.49,393.849l-11.366,13.46 L312.957,277.407l-24.65,23.454c-18.268,16.776-46.345,16.776-64.614,0l-24.65-23.454L39.876,407.309l-11.366-13.46l149.44-136.517 L27.531,115.968l8.893-11.783L256,270.56l219.575-166.375l8.886,11.783l-150.41,141.364L483.49,393.849z"></path>
                            </svg>
                        </div>
                        <div>enquiries@horaquest.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
