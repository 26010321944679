import React from 'react';

const services = [
    { name: 'Numerology', description: 'Divine or mystical relationship between a number and one or more coinciding events.' },
    { name: 'Astrology', description: 'Exploring the influence of celestial bodies.' },
    { name: 'Vastu', description: 'Harnessing positive energy in living spaces.' },
];

export default function ServiceCard() {
    return (
        <div className="px-[100px] py-3 bg-green-100">
            <div className="cursor-pointer">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="my-4 py-10 px-5 text-center border-2 border-green-700 rounded-xl transition-transform duration-200 hover:bg-green-200 hover:shadow-lg transform hover:scale-105"
                    >
                        <h3 className="text-lg font-bold text-green-900">{service.name}</h3>
                        <p className="text-sm text-green-800">{service.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
